@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("./assets/png/image2.png");
  background-repeat: no-repeat;
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.spinner-border {
  vertical-align: -0.125em;
  border: 0.25em solid;
  border-right-color: transparent;
}

@font-face {
  font-family: 'Montserrat';
  src: url("./fonts/Montserrat-Regular.ttf") format('truetype');
}

@font-face {
  font-family: 'Montserrat Bold';
  src: url("./fonts/Montserrat-Bold.ttf") format('truetype');
}

@font-face {
  font-family: 'Montserrat ExtraBold';
  src: url("./fonts/Montserrat-ExtraBold.ttf") format('truetype');
}

@font-face {
  font-family: 'Montserrat Italic';
  src: url("./fonts/Montserrat-Italic.ttf") format('truetype');
}